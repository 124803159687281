import React from "react"
import ContentfulLink from "components/contentfulLink"

import styles from "styles/components/cta-block.module.scss"

const CtaBlock = ({ title, subtitle, link, style }) => {
  return (
    <div className={styles.container} style={style}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
      <ContentfulLink {...link} className={styles.button}>
        {link ? link.linkText : ""}
      </ContentfulLink>
    </div>
  )
}

export default CtaBlock
