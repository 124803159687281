import React from "react"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import classNames from "classnames"

import styles from "styles/components/social-share.module.scss"

import LinkedInIcon from "images/icons/linkedin.svg"
import FacebookIcon from "images/icons/facebook.svg"
import TwitterIcon from "images/icons/twitter.svg"

const SocialShare = ({ title, url, centered, className, style }) => {
  return (
    <div
      className={classNames(styles.share, className, {
        [styles.centered]: centered,
      })}
      style={style}
    >
      <h2>{title}</h2>
      <div className={styles.iconContainer}>
        <LinkedinShareButton url={url}>
          <LinkedInIcon />
        </LinkedinShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon />
        </TwitterShareButton>
      </div>
    </div>
  )
}

export default SocialShare
